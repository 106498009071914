import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import QuizDetails from './QuizDetails';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import { ConfirmationModal, DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';
import { CustomTable } from '../../components/ReactTable/ReactTable';

const Quizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [objEditDelete, setObjEditDelete] = useState(null);
  const [key, setKey] = useState('active');
  const [loading, setLoading] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const selectedQuizzes = useRef([])

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      getQuestionCategories();
      getCampaigns();
      getAgeGroups();
    };
    fetchData();
  }, []);

  const getCampaigns = async() =>  {
    try {
      let res = await APIs.getCampaigns('hu');
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
    }
  }


  const getCampaignQuizzes = async(id) =>  {
    try {
      setLoading(true)
      let res = await APIs.getCampaignQuizzes(id);
      setLoading(false)
      return res.data;
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const getQuestionCategories = async() =>  {
    try {
      let res = await APIs.getQuestionCategories('hu');
      setQuestionCategories(res.data.filter(cat => !cat.is_hidden))
    } catch (err) {
      console.error(err);
    }
  }

  const getAgeGroups = async() =>  {
    try {
      let res = await APIs.getAgeGroups('hu');
      setAgeGroups(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const deleteQuiz = async(id) =>  {
    try {
      await withToast(APIs.deleteQuiz(id));
      setSelectedQuiz(null);
      onCloseModal();
      setQuizzes(await getCampaignQuizzes(selectedCampaign));
    } catch (err) {
      console.error(err);
    }
  }

  const reRenderTab = () => {
    setKey('');
    setKey(key);
  }

  const deleteAll = async() =>  {
    try {
      const obj = {
        quiz_ids_to_delete: selectedQuizzes.current
      }
      await withToast(APIs.deleteQuizzes(obj));
      selectedQuizzes.current = [];
      setSelectedQuiz(null);
      onCloseModal();
      reRenderTab();
      setQuizzes(await getCampaignQuizzes(selectedCampaign));
    } catch (err) {
      console.error(err);
    }
  }


  const regenerateAll = async() =>  {
    try {
      setLoadingComponent(true);
      await withToast(APIs.generateCampaigQuizzes(selectedCampaign));
      onCloseModal();
      setQuizzes(await getCampaignQuizzes(selectedCampaign));
      setLoadingComponent(false);
    } catch (err) {
      console.error(err);
      setLoadingComponent(false);
    }
  }

  const cell = (row) => {
    return(
      <div>
          {row.value}
      </div>
    )
  }

  const columns1 = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    }
  ]

  const columns2 = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
    {
      Header: t('category'),
      accessor: "category.name",
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...questionCategories.map(el => ({label: el.name, value: el.id}))],
    },
    {
      Header: t('num_pages'),
      accessor: 'num_pages',
      Cell: cell,
    },
    {
      Header: t('num_questions'),
      accessor: 'num_questions',
    },
    {
      Header: t('age_group'),
      accessor: 'age_group.name',
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...ageGroups.map(el => ({label: el.name, value: el.id}))],
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center custom-actions-column">
            <PencilFill className="custom-icon me-2" color="gray" size={16}
            onClick={() => {setShowModal('new_edit'), setSelectedQuiz(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20}
            onClick={() => {setShowModal('delete'), setObjEditDelete(row.row.original.id)}}/>
          </div>
        );
      },
      disableFilters: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40
    },
  ]

  const onCloseModal = () => {
    setShowModal('');
    selectedQuizzes.current = [];
  }

  const onRefresh = async() => {
    if(selectedCampaign) setQuizzes(await getCampaignQuizzes(selectedCampaign));
  }

  const data = React.useMemo(() => quizzes)
  return (
    <>
      <h3>{t('quizzes')}</h3>
      <Button
        size="sm"
        className="mb-2 custom-button"
        onClick={() => {
          setSelectedQuiz(null);
          setShowModal('new_edit');
        }}
      >
        <span>
          <Plus color="white" size={20} />
        </span>
        {t('buttons.add')}
      </Button>
      {selectedCampaign && 
      <div className='float-end'>
        <Button
          size="sm"
          className="mb-2 custom-button me-2"
          onClick={() => {
            setSelectedQuiz(null);
            setShowModal('regenerate_all');
          }}
        >
          <span>
            <Plus color="white" size={20} />
          </span>
          {t('buttons.regenerate_questions')}
        </Button>
        <Button
          size="sm"
          className="mb-2 custom-button"
          onClick={() => {
            setSelectedQuiz(null);
            setShowModal('delete_all');
          }}
        >
          <span>
            <X color="white" size={20} />
          </span>
          {t('buttons.delete_quizzes')}
        </Button>
      </div>}
      <Row>
        <Col lg={2}>
          <CustomTable
            columns={columns1}
            data={campaigns}
            trClick={async (row) => {}}
            onSelect={async (data) => {
              if (data) {
                setSelectedCampaign(data);
                setQuizzes(await getCampaignQuizzes(data));
              }
            }}
            selectedId={selectedCampaign}
          />
        </Col>
        <Col lg={10}>
          {showModal === 'new_edit' &&  <QuizDetails
              onCancel={() => onCloseModal()}
              selectedRow={selectedQuiz}
              campaigns={campaigns}
              questionCategories={questionCategories}
              onChangeQuestionCategory={questions => setAllQuestions(questions)}
              onRefresh={()=> onRefresh()}
              ageGroups={ageGroups}
            />
          }
          {selectedCampaign &&
          <>
           <Tabs id="content"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
           >
              <Tab eventKey="active" title={t('active')}>
                {key === "active" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'active')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                    checkboxHook={true}
                    onSelectedRowsChange={(rows) => {
                      if(rows?.length > 0) {
                        selectedQuizzes.current = rows?.map(el => el.id)
                      }
                    }}
                  />
                }
              </Tab>
              <Tab eventKey="development" title={t('development')}>
                {key === "development" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'development')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                    checkboxHook={true}
                    onSelectedRowsChange={(rows) => {
                      if(rows?.length > 0) {
                        selectedQuizzes.current = rows?.map(el => el.id)
                      }
                    }}
                  />
                }
              </Tab>
              <Tab eventKey="testing" title={t('testing')}>
                {key === "testing" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'testing')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                    checkboxHook={true}
                    onSelectedRowsChange={(rows) => {
                      if(rows?.length > 0) {
                        selectedQuizzes.current = rows?.map(el => el.id)
                      }
                    }}
                  />
                }
              </Tab>
              <Tab eventKey="expired" title={t('expired')}>
                {key === "expired" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'expired')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                    checkboxHook={true}
                    onSelectedRowsChange={(rows) => {
                      if(rows?.length > 0) {
                        selectedQuizzes.current = rows?.map(el => el.id)
                      }
                    }}
                  />
                }
              </Tab>
            </Tabs>
          </>
        }
        </Col>
      </Row>
      {showModal === 'delete' && <DeleteModal
        onOK={() => deleteQuiz(objEditDelete)}
        onCancel={() => onCloseModal()}
        />
      }
      {showModal === 'delete_all' && (
        <DeleteModal
          content={
            selectedQuizzes.current.length > 0
            ? <>
              <div>
                {t('messages.quizzes_will_be_deleted', {count: selectedQuizzes.current.length, type: key })}
              </div>
              <div>{t('messages.want_to_delete')}</div>
            </>
            : <>
              <div>{t('messages.quizzes_nothing_selected')}</div>
            </>
          }
          onOK={() => deleteAll(objEditDelete)}
          onCancel={() => onCloseModal()}
        />
      )}
      {showModal === 'regenerate_all' && (
        <ConfirmationModal
          content={
            <>
              {t('messages.want_to_regenerate')}
              {loadingComponent && 
                <div>
                  {t('generating_questions')}
                </div>
              }
            </>
          }
          onOK={() => regenerateAll()}
          onCancel={() => onCloseModal()}
          disabledOKButton={loadingComponent}
          disabledCancelButton={loadingComponent}
        />
      )}
    </>
  );
}
export default Quizzes;
