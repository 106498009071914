import React, { useState, useEffect, useRef} from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import APIs from '../../api/apiCalls';
import CoinTypeDetails from './CoinTypeDetails';
import { DeleteModal } from '../../components/Modal';
import { withToast } from '../../api/util';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';
import { TitleComponent } from '../../components/TitleComponent';

const PhysicalCoins = () => {
  const [coinTypes, setCoinTypes] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [themes, setThemes] = useState([]);
  const [selectedCoinType, setSelectedCoinType] = useState(null);
  const [showModal, setShowModal] = useState('');
  const [objToDelete, setObjToDelete] = useState(null);
  const [key, setKey] = useState('active');
  const { t } = useTranslation();
  const [language, setLanguage] = useState('hu');
  const selectedCoinTypes = useRef([])

  useEffect(() => {
    const fetchData = async () => {
      const [coinsRes, campaignsRes, themesRes, categoriesRes] = await Promise.all([
        CoinsAPI.getCoinTypes({physical: 1}, language),
        APIs.getCampaigns(language),
        CoinsAPI.getThemes(language),
        //APIs.getQuestionCategories()
      ])
      setCampaigns(campaignsRes.data);
      setCoinTypes(coinsRes.data);
      setThemes(themesRes.data);
    }
    fetchData();
  }, [language])

  const getCoins = async() =>  {
    try {
      let res = await CoinsAPI.getCoinTypes({physical: 1}, language);
      setCoinTypes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createCoinType = async(obj) =>  {
    setShowModal('');
    try {
      const res = await withToast(CoinsAPI.createCoinType(obj, language));
      getCoins();
      setSelectedCoinType(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const editCoinType = async(obj) =>  {
    setShowModal('');
    try {
      await withToast(CoinsAPI.editCoinType(selectedCoinType.id, obj, language));
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteCoinType = async(id) =>  {
    setShowModal('');
    try {
      await withToast(CoinsAPI.deleteCoinType(id));
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }

  const reRenderTab = () => {
    setKey('');
    setKey(key);
  }

  const deleteAll = async() =>  {
    try {
      const obj = {
        coin_types_ids_to_delete: selectedCoinTypes.current
      }
      await withToast(CoinsAPI.deleteCoinTypes(obj));
      selectedCoinTypes.current = [];
      setSelectedCoinType(null);
      onCloseModal();
      reRenderTab();
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }


  const cell = (row) => {
    return(
      <div>
        {row.value}
      </div>
    )
  }

  const cellNum = (row) => {
    return (
      <div className="float-end">
       {row.value}
      </div>
    );
  }


  const columns = [
    {
      Header: t('name'),
      accessor: 'name',
      Cell: cell,
    },
    {
      Header: t('campaign'),
      accessor: 'campaign.name',
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [
        { label: t('all'), value: '' },
        ...campaigns.map((el) => ({ label: el.name, value: el.id })),
      ],
    },
    {
      Header: t('date_of_issue'),
      accessor: 'date_of_issue',
      Cell: cell,
    },
    {
      Header: t('num_coins'),
      accessor: 'num_coins',
      Cell: cellNum,
    },
    {
      Header: t('quantity_in_use'),
      accessor: 'quantity_in_use',
      Cell: cellNum,
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: (row) => {
        if (row.row.isGrouped) return <></>;
        return (
          <div className="text-center custom-actions-column">
            <PencilFill
              className="custom-icon me-2"
              color="gray"
              size={16}
              onClick={() => {
                setShowModal('new_edit');
                setSelectedCoinType(row.row.original);
              }}
            />
            <X
              className="custom-icon"
              color="red"
              size={20}
              onClick={() => {
                setShowModal('delete');
                setObjToDelete(row.row.original.id);
              }}
            />
          </div>
        );
      },
      disableFilters: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
    },
  ];

  const onCloseModal = () => {
    setShowModal('');
    selectedCoinTypes.current = [];
  }

  return (
    <>
      <TitleComponent
        title={t('physical_coins')}
        language={language}
        setLanguage={setLanguage}
      />
      <Button
        size="sm"
        className="mb-2 custom-button"
        onClick={() => {
          setSelectedCoinType(null);
          setShowModal('new_edit');
        }}
      >
        <span>
          <Plus color="white" size={20} />
        </span>
        {t('buttons.add')}
      </Button>
      <div className='float-end'>
        <Button
          size="sm"
          className="mb-2 custom-button"
          onClick={() => {
            setSelectedCoinType(null);
            setShowModal('delete_all');
          }}
        >
          <span>
            <X color="white" size={20} />
          </span>
          {t('buttons.delete_coin_types')}
        </Button>
      </div>
      <Tabs
        id="content"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="active" title={t('active')}>
          {key === "active" &&
            <CheckboxTablePaginated
              columns={columns}
              data={coinTypes?.filter((el) => el.status === 'active')}
              selectedId={selectedCoinType?.id}
              total={false}
              filterTable={true}
              pagination={true}
              checkboxHook={true}
              onSelectedRowsChange={(rows) => {
                console.log('rows?.length', rows?.length)
                if(rows?.length > 0) {
                  selectedCoinTypes.current = rows?.map(el => el.id)
                }
              }}
            />
          }
        </Tab>
        <Tab eventKey="development" title={t('development')}>
          {key === "development" &&
            <CheckboxTablePaginated
              columns={columns}
              data={coinTypes?.filter((el) => el.status === 'development')}
              selectedId={selectedCoinType?.id}
              total={false}
              filterTable={true}
              pagination={true}
              checkboxHook={true}
              onSelectedRowsChange={(rows) => {
                if(rows?.length > 0) {
                  selectedCoinTypes.current = rows?.map(el => el.id)
                }
              }}
            />
          }
        </Tab>
        <Tab eventKey="testing" title={t('testing')}>
          {key === "testing" &&
            <CheckboxTablePaginated
              columns={columns}
              data={coinTypes?.filter((el) => el.status === 'testing')}
              selectedId={selectedCoinType?.id}
              total={false}
              filterTable={true}
              pagination={true}
              checkboxHook={true}
              onSelectedRowsChange={(rows) => {
                if(rows?.length > 0) {
                  selectedCoinTypes.current = rows?.map(el => el.id)
                }
              }}
            />
          }
        </Tab>
      </Tabs>
      {showModal === 'delete' && (
        <DeleteModal
          onOK={() => deleteCoinType(objToDelete)}
          onCancel={() => onCloseModal()}
        />
      )}
      {showModal === 'delete_all' && (
        <DeleteModal
          content={
            selectedCoinTypes.current.length > 0
            ? <>
              <div>
                {t('messages.coins_will_be_deleted', {
                  count: selectedCoinTypes.current.length,
                  type: key,
                })}
              </div>
              <div>{t('messages.want_to_delete')}</div>
            </>
            : <>
              <div>{t('messages.coins_nothing_selected')}</div>
            </>
          }
          onOK={() => deleteAll()}
          onCancel={() => onCloseModal()}
        />
      )}
      {showModal === 'new_edit' && (
        <CoinTypeDetails
          onCancel={() => onCloseModal()}
          selectedRow={selectedCoinType}
          onCreate={(obj) => createCoinType(obj)}
          onEdit={(obj) => editCoinType(obj)}
          campaigns={campaigns}
          themes={themes}
          is_physical={true}
          language={language}
        />
      )}
    </>
  );
}
export default PhysicalCoins;
